import '../../../assest/css/user/home/participation.css'
export default function Participation()
{
    return(
        <div className="participation-wrapper container">
            <h2 className="heading-2">
                أنواع الاشتراكات وأنظمتها <br/>
                <span className="text">لأنك تستحق ميزتها لك</span>
            </h2>
        </div>
    )
}