import React from 'react'
import Layout from '../../components/studentDash/Layout'

export default function HomeStudentDash() {
    return (
        <Layout>
            
        </Layout>
    )
}
