import React from 'react'
import Layout from '../../components/teacherDash/Layout'
export default function HomeTeacherDash() {
    return (
        <div>
            <Layout>
            </Layout>
        </div>
    )
    }
