import React from 'react'
import Layout from '../../components/superadminDash/Layout'

function Superadminf() {
  return (
    <div>
        <Layout />

    </div>
  )
}

export default Superadminf